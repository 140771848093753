import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {
    Card,
    CardBody, CardFooter,
    ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    saveFolderUpdate, useFoldersUpdatesEditState
} from "../../../../../redux/store/folders";
import {Form} from "./Form";
import {AEButton} from "../../../../../_ae/components/buttons";

export const Edit = ({history, match: {params: {id},}}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {isLoading, data, error} = useFoldersUpdatesEditState();


    const saveBtnRef = useRef();
    const saveBtnRefClick = () => {
        if (saveBtnRef && saveBtnRef.current) {
            const {current} = saveBtnRef;
            current.click()
        }
    };

    return (
        <Card>
            {isLoading && <ModalProgressBar/>}
            <CardFooter className={'d-flex justify-content-end align-items-center'}>
                <AEButton
                    variant={'light'}
                    onClick={history.goBack}
                >
                    <i className="fa fa-arrow-left"/>
                    {formatMessage({id: 'BACK'})}
                </AEButton>
                <AEButton
                    className="ml-2"
                    onClick={saveBtnRefClick}
                >
                    {formatMessage({id: 'SAVE'})}
                </AEButton>
            </CardFooter>

            <CardBody className={'pt-10'}>
                <Formik
                    enableReinitialize
                    initialErrors={error}
                    validationSchema={
                        Yup.object().shape({
                            id: Yup.number().nullable(),
                            status: Yup.string().required(),
                            cabinetNote: Yup.string(),
                            attachments: Yup.array(),
                            assignTo: Yup.object()/*.required()*/,
                            city: Yup.object().required(),
                        })
                    }
                    initialValues={data}
                    onSubmit={({attachments, ...values}) => {
                            dispatch(saveFolderUpdate({...values, folderId: id}, {attachments}))
                    }}
                    render={formik => (
                        <Form
                            {...formik}
                            btnRef={saveBtnRef}
                        />)}
                />
            </CardBody>

        </Card>
    );
}

