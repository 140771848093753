import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import {useIntl} from "react-intl";
import {ROLES} from "../../../../../_ae/config";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
// import {
//   fetchLocationForUsersFilter,
//   useLocationsUsersFilterState
// } from "../../../../../redux/store/cities";
import {useRolesListUsersEditState} from "../../../../../redux/store/roles";
import {fetchForCitiesSelect, useCitiesForSelectState} from "../../../../../redux/store/cities";
import {localField} from "../../../../../_ae/helpers/UIHelper";

//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
                       setFieldValue
}) => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const {data: cities} = useCitiesForSelectState()
  const {data: roles} = useRolesListUsersEditState()

  useEffect(()=>{
    dispatch(fetchForCitiesSelect())
  }, [dispatch])

  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg-6"}>
          <AEField
            name="firstName"
            label={'FIRST_NAME'}
          />
        </div>
        <div className={"col-lg-6"}>
          <AEField
            name="lastName"
            label={'LAST_NAME'}
          />
        </div>
      </div>
      <div className="form-group row py-1">
        <div className={`col-lg-6`}>
          <AEAutocompleteField
            name="city"
            label={'CITY'}
            options={cities}
            getOptionLabel={o=>o[localField()]}
          />
        </div>
        <div className={`col-lg-6`}>
          <AEAutocompleteField
            name="role"
            label={'ROLE'}
            options={roles}
            getOptionLabel={role=>role.name}
          />
        </div>
      </div>
      <div className="form-group d-flex flex-row py-1">
        <div className="flex-fill">
          <AEField
            name="email"
            label={'EMAIL'}
          />
        </div>
      </div>
      {
        ! values.id &&
        <div className={'row'}>
          <div className={'form-group col-lg'}>
            <AEField
              name="password"
              label={'PASSWORD'}
              type={'password'}
            />
          </div>
          <div className={'form-group col-lg'}>
            <AEField
              name="_confirm"
              label={'PASSWORD_CONFIRM'}
              type={'password'}
            />
          </div>
        </div>
      }

      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

