import React, {Suspense, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {ContentRoute, Layout, LayoutSplashScreen} from "../_metronic/layout";
import ErrorsPage from "./pages/ErrorsPages/ErrorsPage";
import {useIntl} from "react-intl";
import {SITE_NAME} from "../_ae/helpers/UIHelper";
import {matchPath} from "react-router";
import {AuthLayout, ForgotPassword, Login, Logout, Registration, ResetPassword} from "./modules/Auth";
import {fetchRoutesForApp, useRoutesForAppState} from "../redux/store/routes";
import {SVG_ICON} from "../_ae/components/svg";
import {EditPassword, EditProfile} from "./modules/Profile";
import {UsersDelete, UsersEdit, UsersList} from "./modules/Users";
import {CitiesDelete, CitiesEdit, CitiesList} from "./modules/Cities";
import {AddUpdate, FoldersDelete, FoldersDetail, FoldersEdit, FoldersList} from "./modules/Folders";
import {RolesDelete, RolesEdit, RolesList} from "./modules/Roles";
import {MODULES, VIEWS} from "../_ae/helpers/RoutingHelpers";

import {EmyErrorPage} from "./pages/ErrorsPages/EmyErrorPage";

export const ROUTES = [
  {
    id: 'AUTH.LOGIN',
    path: `/auth/login`,
    // svg: SVG_ICON.ADD_USER,
    component: Login,
    context: 'auth',
    views: []
  },
  {
    id: 'AUTH.REGISTER',
    path: `/auth/registration`,
    // svg: SVG_ICON.ADD_USER,
    component: Registration,
    context: 'auth',
    views: []
  },
  {
    id: 'AUTH.FORGOT',
    path: `/auth/password/forgot`,
    // svg: SVG_ICON.ADD_USER,
    component: ForgotPassword,
    context: 'auth',
    views: []
  },
  {
    id: 'AUTH.RESET',
    path: `/auth/password/reset/:token`,
    // svg: SVG_ICON.ADD_USER,
    component: ResetPassword,
    context: 'auth',
    views: []
  },
  // Profile
  {
    id: 'USER.PROFILE.EDIT',
    path: `/${MODULES.PROFILE}/edit`,
    svg: SVG_ICON.EDIT,
    component: EditProfile,
    context: MODULES.PROFILE,
    views: []
  },
  {
    id: 'USER.PASSWORD.EDIT',
    path: `/${MODULES.PROFILE}/password/edit`,
    svg: SVG_ICON.EDIT,
    component: EditPassword,
    context: MODULES.PROFILE,
    views: []
  },
  // users
  {
    id: 'USERS.NEW',
    path: `/${MODULES.USERS}/new`,
    svg: SVG_ICON.ADD_USER,
    component: UsersEdit,
    context: MODULES.USERS,
    views: [VIEWS.ACTION]
  },
  {
    id: 'USERS.EDIT',
    path: `/${MODULES.USERS}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: UsersEdit,
    context: MODULES.USERS,
    views: []
  },
  {
    id: 'USERS.DELETE',
    path: `/${MODULES.USERS}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: UsersDelete,
    context: MODULES.USERS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'USERS.LIST',
    path: `/${MODULES.USERS}/list`,
    svg: SVG_ICON.USERS,
    component: UsersList,
    context: MODULES.USERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // cities
  {
    id: 'CITIES.NEW',
    path: `/${MODULES.CITIES}/new`,
    svg: SVG_ICON.PLUS,
    component: CitiesEdit,
    context: MODULES.CITIES,
    views: [VIEWS.ACTION]
  },
  {
    id: 'CITIES.EDIT',
    path: `/${MODULES.CITIES}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: CitiesEdit,
    context: MODULES.CITIES,
    views: []
  },
  {
    id: 'CITIES.DELETE',
    path: `/${MODULES.CITIES}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: CitiesDelete,
    context: MODULES.CITIES,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'CITIES.LIST',
    path: `/${MODULES.CITIES}/list`,
    svg: SVG_ICON.CITIES,
    component: CitiesList,
    context: MODULES.CITIES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  //folders
  {
    id: 'FOLDERS.NEW',
    path: `/${MODULES.FOLDERS}/new`,
    svg: SVG_ICON.PLUS,
    component: FoldersEdit,
    context: MODULES.FOLDERS,
    views: [VIEWS.ACTION]
  },
  {
    id: 'FOLDERS.EDIT',
    path: `/${MODULES.FOLDERS}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: FoldersEdit,
    context: MODULES.FOLDERS,
    views: []
  },
  {
    id: 'FOLDERS.DELETE',
    path: `/${MODULES.FOLDERS}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: FoldersDelete,
    context: MODULES.FOLDERS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'FOLDERS.DETAIL',
    path: `/${MODULES.FOLDERS}/:id/detail`,
    svg: SVG_ICON.LIST,
    component: FoldersDetail,
    context: MODULES.FOLDERS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'FOLDERS.UPDATES.NEW',
    path: `/${MODULES.FOLDERS}/:id/add-update`,
    svg: SVG_ICON.PLUS,
    component: AddUpdate,
    context: MODULES.FOLDERS,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'FOLDERS.LIST',
    path: `/${MODULES.FOLDERS}/list`,
    svg: SVG_ICON.FOLDERS,
    component: FoldersList,
    context: MODULES.FOLDERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Roles
  {
    id: 'ROLES.NEW',
    path: `/${MODULES.ROLES}/new`,
    svg: SVG_ICON.PLUS,
    component: RolesEdit,
    context: MODULES.ROLES,
    views: [VIEWS.ACTION]
  },
  {
    id: 'ROLES.EDIT',
    path: `/${MODULES.ROLES}/:id/edit`,
    svg: SVG_ICON.EDIT,
    component: RolesEdit,
    context: MODULES.ROLES,
    views: []
  },
  {
    id: 'ROLES.DELETE',
    path: `/${MODULES.ROLES}/:id/delete`,
    svg: SVG_ICON.DELETE,
    component: RolesDelete,
    context: MODULES.ROLES,
    views: [VIEWS.DIALOG]
  },
  {
    id: 'ROLES.LIST',
    path: `/${MODULES.ROLES}/list`,
    svg: SVG_ICON.ROLES,
    component: RolesList,
    context: MODULES.ROLES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
]

export const getRoutes = (context) => {
  if (!context) return ROUTES

  return ROUTES.filter(route => route.context === context)
}

export function Routes() {
  const dispatch = useDispatch();
  const {isAuthorized, authUser} = useSelector( ({auth}) => ({ authUser: auth.user, isAuthorized: auth.user != null, }), shallowEqual );
  const {data: routes, isLoading} = useRoutesForAppState();
  const {formatMessage} = useIntl();
  const location = useLocation();
  useEffect(()=>{
      const currentRoute = routes.find(r=>matchPath(location.pathname, {path: r.path}))
      let title = SITE_NAME;
      if(currentRoute) {
        title += ` | ${formatMessage({id: currentRoute.id})}`
      }

      document.title = title;
    },[routes, location.pathname])

  useEffect(()=>{
    if (authUser && location.pathname !== '/logout') {
      dispatch(fetchRoutesForApp())
    }
  },[authUser])


  // console.log(location.pathname !== '/logout', !!authUser, isLoading, routes.length === 0)
  if ((isLoading || routes.length === 0) && !!authUser && location.pathname !== '/logout') {
    return <LayoutSplashScreen />
  }

  return (
    <>
      <Switch>

        <Route path="/error" component={ErrorsPage}/>
        <Route path="/logout" component={Logout}/>

        {
          !isAuthorized ?
            <AuthLayout >
              <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                  {/*FIX ME*/}
                  <Redirect exact from="/" to="/auth"/>
                  <Redirect exact from="/auth" to="/auth/login"/>
                  {
                    getRoutes('auth').map(route=>(
                      <ContentRoute
                        key={route.id}
                        {...route} />
                        ))
                  }
                </Switch>
              </Suspense>
            </AuthLayout> :
            <Layout>
              <Suspense fallback={<LayoutSplashScreen/>}>
                <Switch>
                  {/*todo redirect /folders*/}
                  <Redirect exact from="/" to="/folders"/>
                  <Redirect exact from="/auth/login" to="/"/>
                  {
                    Object
                      .values(MODULES)
                      .filter(key=>![MODULES.PROFILE].includes(key))
                      .map(moduleName=>(
                        <Redirect
                          key={moduleName}
                          exact
                          from={`/${moduleName}`}
                          to={`/${moduleName}/list`}
                        />
                    ))
                  }

                  {
                    routes
                      .map((route, i)=> {
                        return (
                          <ContentRoute
                            key={i}
                            {...route}
                          />
                        )
                      })
                  }
                  <Redirect to="/error"/>
                </Switch>
              </Suspense>
            </Layout>
        }
      </Switch>
    </>
  );
}
