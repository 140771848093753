import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";

export const API_URI = toAbsoluteApiUrl("/users");

const normalize = _entity => {
    const {id, firstName, lastName, email, password} = _entity;
    const city = {id: _entity.city.id}
    const role = {id: _entity.role.id}
    const username = email

    return {id, firstName, lastName, email, password, role, city, username}
}

export const all = (metadata) => axios.get(API_URI, {params: {meta: metadata}});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = entity => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity)), axiosFormDataConfigs);


