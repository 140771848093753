import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useIntl} from "react-intl";
import {
    AEAutocompleteField,
    AEField, AEFileField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {fetchUsersForFoldersEdit, useUsersForFoldersEditState} from "../../../../../redux/store/users";
import {fetchForCitiesSelect, useCitiesForSelectState} from "../../../../../redux/store/cities";
import {getFullName} from "../../../Users/components/FullName";
import {AERole} from "../../../../../_ae/components/AERole";
import {CONFIG} from "../../../../../_ae/config";


export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {data: users} = useUsersForFoldersEditState()
    const {data: cities} = useCitiesForSelectState()

    useEffect(() => {
        dispatch(fetchUsersForFoldersEdit())
        dispatch(fetchForCitiesSelect())
    }, [dispatch])

    return (
        <div className="form form-label-right">
            <div className="form-group row">
                <div className={"col-lg-6"}>
                    <AEField
                        name="reference"
                        label={'REFERENCE'}
                    />
                </div>
            </div>
            <div className="form-group row">

                <div className={"col-lg-6"}>
                    <AEField
                        name="clientName"
                        label={'CLIENT_NAME'}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <AEField
                        name="phone"
                        label={'PHONE'}
                    />
                </div>
            </div>
            <div className="form-group row">
                <div className={"col-lg-6"}>
                    <AEField
                        name="matricule"
                        label={'MATRICULE'}
                    />
                </div>

                <div className={"col-lg-6"}>
                    <AEField
                        name="marque"
                        label={'MARQUE'}
                    />
                </div>
            </div>

            <div className="form-group row">

                <div className={"col-lg-6"}>
                    <AEAutocompleteField
                        name="city"
                        label={'CITY'}
                        options={cities}
                        getOptionLabel={city => city.name}
                    />
                </div>

            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="notes"
                        label={'NOTES'}
                        type={'textarea'}
                        rows={8}
                    />
                </div>
            </div>

            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEFileField
                        name="attachments"
                        label={'ATTACHMENTS'}
                        multiple
                    />
                </div>
            </div>


            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />
        </div>
    );
}

