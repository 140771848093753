import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {
    Card,
    CardBody, CardHeader,
    ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import * as Yup from "yup";
import {
    fetchFolderForEdit, resetFolderForEdit,
    saveFolder,
    useFoldersEditState
} from "../../../../../redux/store/folders";
import {Form} from "./Form";
import {AEButton} from "../../../../../_ae/components/buttons";

export const Edit = ({history, match: {params: {id},}}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {isLoading, data, error} = useFoldersEditState();

    useEffect(() => {
        dispatch(
            id ?
                fetchFolderForEdit(id) :
                resetFolderForEdit()
        );
    }, [id, dispatch]);

    const saveBtnRef = useRef();
    const saveBtnRefClick = () => {
        if (saveBtnRef && saveBtnRef.current) {
            const {current} = saveBtnRef;
            current.click()
        }
    };

    return (
        <Card>
            {isLoading && <ModalProgressBar/>}
            <CardHeader className={'d-flex justify-content-end align-items-center'}>
                    <AEButton
                        variant={'light'}
                        onClick={history.goBack}
                    >
                        <i className="fa fa-arrow-left"/>
                        {formatMessage({id: 'BACK'})}
                    </AEButton>
                    <AEButton
                        className="ml-2"
                        onClick={saveBtnRefClick}
                    >
                        {formatMessage({id: 'SAVE'})}
                    </AEButton>
            </CardHeader>
            <CardBody className={'pt-10'}>
                <Formik
                    enableReinitialize
                    initialErrors={error}
                    validationSchema={
                        Yup.object().shape({
                            id: Yup.number().nullable(),
                            reference: Yup.string().required(),
                            phone: Yup.string().matches(/^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/, '(+212)xxxxxxxxxx').required(),
                            clientName: Yup.string().required(),
                            matricule: Yup.string().required(),
                            marque: Yup.string().required(),
                            notes: Yup.string(),
                            city: Yup.object().required(),
                            attachments: Yup.array(),
                        })
                    }
                    initialValues={data}
                    onSubmit={({attachments, ...values}) => {
                        dispatch(saveFolder(values, {attachments}))
                    }}
                    render={formik => (
                        <Form
                            {...formik}
                            btnRef={saveBtnRef}
                        />)}
                />
            </CardBody>
        </Card>
    );
}

