import React, {useEffect} from "react";
import {useIntl} from "react-intl";
import {
    AEAutocompleteField,
    AEField, AEFileField
} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {getStatus, getStatusKeys, Status} from "../../components/Status";
import {CONFIG} from "../../../../../_ae/config";
import {getFullName} from "../../../Users/components/FullName";
import {AERole} from "../../../../../_ae/components/AERole";
import {fetchForCitiesSelect, useCitiesForSelectState} from "../../../../../redux/store/cities";
import {fetchUsersForFoldersEdit, useUsersForFoldersEditState} from "../../../../../redux/store/users";
import {useDispatch} from "react-redux";


//todo server validation errors
export const Form = ({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         btnRef,
                     }) => {

    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const {data: users} = useUsersForFoldersEditState()
    const {data: cities} = useCitiesForSelectState()

    useEffect(() => {
        dispatch(fetchUsersForFoldersEdit())
        dispatch(fetchForCitiesSelect())
    }, [dispatch])


    return (
        <div className="form form-label-right">
            <div className="form-group row">
                <div className={"col-lg-6"}>
                    <AEAutocompleteField
                        name="city"
                        label={'CITY'}
                        options={cities}
                        getOptionLabel={city => city.name}
                    />
                </div>

                <AERole
                    roles={[CONFIG.roles.A]}
                    exceptionMode
                >
                    <div className={`col-md`}>
                        <AEAutocompleteField
                            name="assignTo"
                            label={'ASSIGN_TO'}
                            options={users.filter(u => u.role.roleId === "ROLE_AGENT")}
                            getOptionLabel={getFullName}
                        />
                    </div>
                </AERole>
            </div>

            <div className="form-group row">
                <div className={`col-md-12`}>
                    <AEAutocompleteField
                        name="status"
                        label={'STATUS'}
                        options={getStatusKeys()}
                        getOptionLabel={o => formatMessage({id: getStatus(o).id})}
                        renderOption={o => <Status statusKey={o}/>}
                    />
                </div>
            </div>


            <div className="form-group row">
                <div className={"col-lg-12"}>
                    <AEField
                        name="cabinetNote"
                        label={'NOTE'}
                        type={'textarea'}
                        rows={12}
                    />
                </div>
            </div>

            <AERole
                roles={[CONFIG.roles.A]}
            >
                <div className="form-group row">
                    <div className={"col-lg-12"}>
                        <AEField
                            name="agentNote"
                            label={'NOTE'}
                            type={'textarea'}
                            rows={12}
                        />
                    </div>
                </div>
            </AERole>

                <div className="form-group row">
                    <div className={"col-lg-12"}>
                        <AEFileField
                            name="attachments"
                            label={'ATTACHMENTS'}
                            multiple
                        />
                    </div>
                </div>

            <button
                style={{display: "none"}}
                ref={btnRef}
                onClick={handleSubmit}
            />
        </div>
    );
}

