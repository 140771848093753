import React, {useEffect} from "react";
import {FormikProvider, useFormik} from "formik";
import {AEButton} from "../../../../../_ae/components/buttons";
import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import {AESVG} from "../../../../../_ae/components/svg";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {useFoldersUsersFilterState, fetchFolderForUsersFilter} from "../../../../../redux/store/users";

import {FullName, getFullName} from "../../../Users/components/FullName";
import {AERole} from "../../../../../_ae/components/AERole";
import {ROLES} from "../../../../../_ae/config";
import {getImportanceKeys, Importance} from "../../components/Importance";

const sorts = [
    {id: 1, prop: "name", label: "NAME"},
    {id: 2, prop: "createdAt", label: "CREATION_DATE"},
    {id: 3, prop: "updatedAt", label: "SORT.UPDATED_AT"},
]

export const Filter = ({setFilters, setSort, sort, setSortAsc}) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl()
    const {data: users} = useFoldersUsersFilterState()

    const formik = useFormik({
        initialValues: {
            search: "",
            assignTo: undefined,
        },
        onSubmit: (values) => {
            const {search, assignTo, /*status,*/} = values;
            let filters = {search};
            if (assignTo) {
                filters.assignTo = {id: assignTo.id}
            }
            // if(status) {
            //   filters.status = status
            // }

            setFilters(filters)
        }
    })

    useEffect(() => {
        formik.handleSubmit()
    }, [formik.values])

    useEffect(() => {
        dispatch(fetchFolderForUsersFilter())
    }, [dispatch])

    const fieldProps = {
        withFeedbackLabel: false,
        validation: false
    }

    return (
        <Card>
            <CardBody>
                <FormikProvider value={formik}>
                    <div className="d-flex">
                        <div className={`flex-fill`}>
                            <AEField
                                name="search"
                                label={'SEARCH'}
                                {...fieldProps}
                            />
                        </div>
                        <AERole roles={[ROLES.C]} exceptionMode>
                            <div className={`flex-fill ml-4`}>
                                <AEAutocompleteField
                                    name="assignTo"
                                    label={'ASSIGNED_TO'}
                                    options={users.filter(u => u.role.roleId === "ROLE_AGENT")}
                                    getOptionLabel={getFullName}
                                    renderOption={o => <FullName user={o}/>}
                                    {...fieldProps}
                                />
                            </div>
                        </AERole>

                        {/*<div className={`flex-fill ml-4`}>*/}
                        {/*  <AEAutocompleteField*/}
                        {/*    name="status"*/}
                        {/*    label={'STATUS'}*/}
                        {/*    options={getStatusKeys()}*/}
                        {/*    getOptionLabel={id=>formatMessage({id})}*/}
                        {/*    renderOption={o=><Status statusKey={o}/>}*/}
                        {/*    {...fieldProps}*/}
                        {/*  />*/}
                        {/*</div>*/}

                        <Dropdown as={ButtonGroup} className={"pl-2"}>
                            <AEButton variant={"light"} fontWeight={"bolder"} onClick={() => {
                                setSortAsc(!sort.asc)
                            }}>
                                <AESVG path={`/Navigation/${sort.asc ? 'UP' : 'DOWN'}-2.svg`} variant={"primary"}/>
                                {
                                    formatMessage({id: sorts.findIndex(o => o.prop === sort.field) !== -1 ? sorts.find(o => o.prop === sort.field).label : "SORT"})
                                }
                            </AEButton>
                            <Dropdown.Toggle split variant="light"/>
                            <Dropdown.Menu className={"py-5"}>
                                {
                                    sorts.map(o => (
                                        <Dropdown.Item key={o.prop} onClick={() => {
                                            setSort(o.prop, o.asc)
                                        }}>
                                            {formatMessage({id: o.label}, {asc: o.asc})}
                                        </Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </FormikProvider>
            </CardBody>
        </Card>
    )
}