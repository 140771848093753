/* eslint-disable no-restricted-imports */
import React, {useEffect, useRef} from "react";
import {Modal, Button} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {FormattedMessage, useIntl} from "react-intl";
import {AEButton} from "../../../../../_ae/components/buttons";
import {Loader} from "../../../../../_ae/components/loader";
import {fetchLogsForFoldersDetail, useLogsListForFoldersDetailState} from "../../../../../redux/store/logs";
import {FullName} from "../../../Users/components/FullName";
import {AEMoment} from "../../../../../_ae/components/moment";
import {FolderDetailsCard} from "../../index";
import {fetchFolderForDetails, useFoldersDetailsState} from "../../../../../redux/store/folders";
import {AEAvatar} from "../../../../../_ae/components/AEAvatar";
import {useReactToPrint} from 'react-to-print';
import {AEMediaDownload} from "../../../../../_ae/components/AEMediaDownload";
import {AELabel} from "../../../../../_ae/components/AELabel";
import {toAbsoluteApiUrl,toAbsoluteUploadUrl} from "../../../../../_metronic/_helpers";
import {MODULES} from "../../../../../_ae/helpers/RoutingHelpers";
import {AELink} from "../../../../../_ae/components/AELink";
import {
    DownloadZipAttachments
} from "../../../../../redux/store/logs";
import {AESVG} from "../../../../../_ae/components/svg";
import axios from "axios";


const ComponentToPrint = React.forwardRef(({data, folder, ...props}, ref) => {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    return (
        <div ref={ref}>

            <FolderDetailsCard
                entity={folder}
                size={'lg'}
            />


            <div className='font-size-h3 font-weight-bold py-4'>
                <FormattedMessage id={'LOGS_HISTORY'}/>
            </div>

            <div className="timeline timeline-3">
                <div className={'className="timeline timeline-3'}>
                    <div className="timeline-items">
                        {
                            folder.logs.map((log, index) => {

                                return (
                                    <div key={log.id} className="timeline-item">
                                        <div className="timeline-media">

                                            {
                                                log.assignTo ?
                                                    <AEAvatar
                                                        entity={log.assignTo}
                                                        size={100}
                                                    />
                                                    :
                                                    <AEAvatar
                                                        entity={log.createdBy}
                                                        size={100}
                                                    />
                                            }
                                        </div>

                                        {/*<AEButton*/}
                                        {/*    className="ml-2"*/}
                                        {/*    OnClick={dispatch(DownloadZipAttachments(log.id))}*/}
                                        {/*    download*/}
                                        {/*>*/}
                                        {/*    {formatMessage({id: 'DOWNLOAD'})}*/}
                                        {/*</AEButton>*/}


                                        <a
                                            href={''}
                                            className={"pl-5"}
                                            download
                                            target='_blank'
                                            onClick={e => {
                                            e.preventDefault();
                                            axios.get(
                                                toAbsoluteApiUrl(`/logs/${log.id}/downloadZip`), {responseType: 'blob'})
                                                .then((response) => {
                                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                                const link = document.createElement('a');
                                                link.href = url;
                                                //link.setAttribute('download', fileName);
                                                document.body.appendChild(link);
                                                link.click();
                                                });
                                            }}
                                        >
                                            <AEButton
                                                className="ml-2"
                                                OnClick={dispatch(DownloadZipAttachments(log.id))}
                                                download
                                            >
                                                {formatMessage({id: 'DOWNLOAD'})}
                                                <AESVG path={"/Files/Download.svg"}/>

                                            </AEButton>
                                            {/*<AESVG path={"/Files/Download.svg"}/>*/}
                                        </a>


                                        <div className="timeline-content">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="mr-2">
                                                    <a href="#"
                                                       className="text-dark-75 text-hover-primary font-weight-bold">

                                                        {
                                                            log.assignTo ?
                                                                <FullName
                                                                    user={log.assignTo}
                                                                />
                                                                :
                                                                <FullName
                                                                    user={log.createdBy}
                                                                />
                                                        }

                                                    </a>
                                                    <span className="text-muted ml-2">
                                                      <AEMoment
                                                          date={log.updatedAt}
                                                          format={'LLLL'}
                                                      />
                                                    </span>
                                                    {
                                                        log.city ?
                                                            <AELabel
                                                                variant={'primary-light'}
                                                                title={log.city.name}
                                                            />
                                                            : ""
                                                    }
                                                    {/*<Status statusKey={log.status}/>*/}
                                                </div>
                                            </div>

                                            <div className={'mt-auto'}>
                                                <div className="separator separator-solid my-2"/>
                                                <div className='font-size-h4 font-weight-bold py-4'>
                                                    <u>
                                                        Note du Consultant
                                                        <a href="#"
                                                           className="text-dark-75 text-hover-primary font-weight-bold ml-1">
                                                            <FullName
                                                                user={log.createdBy}
                                                            /> :
                                                        </a>
                                                    </u>
                                                </div>
                                                <div className="">
                                                    <p className="p-0 ml-2">
                                                        {log.cabinetNote}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className={'mt-auto'}>
                                                <div className="separator separator-solid my-2"/>
                                                <div className='font-size-h4 font-weight-bold py-4'>
                                                    <u>
                                                        Note de l'agent
                                                        <a href="#"
                                                           className="text-dark-75 text-hover-primary font-weight-bold ml-1">
                                                            {
                                                                log.assignTo &&
                                                                <FullName
                                                                    user={log.assignTo}
                                                                />
                                                            }:
                                                        </a>
                                                    </u>
                                                </div>
                                                <div className="">
                                                    <p className="p-0 ml-2">
                                                        {log.agentNote}
                                                    </p>
                                                </div>
                                            </div>

                                            {
                                                log.attachments &&
                                                log.attachments.length > 0 &&
                                                <div className={'mt-auto'}>
                                                    <div className="separator separator-solid my-2"/>
                                                    <div className='font-size-h4 font-weight-bold py-4'>
                                                        <FormattedMessage id={'ATTACHMENTS'}/>:
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            log.attachments.map(attachment => (
                                                                <div className="col-12 col-md-3">
                                                                    <AEMediaDownload
                                                                        key={attachment.id}
                                                                        id={attachment.id}
                                                                        target={'folder_log_attachments'}
                                                                        fileName={attachment.fileName}
                                                                    />
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})


export function Details({history, match}) {
    const dispatch = useDispatch();
    const {formatMessage} = useIntl();
    const {isLoading, data, metadata} = useLogsListForFoldersDetailState()
    const {data: folder} = useFoldersDetailsState()

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        bodyClass: "p-20"
        // copyStyles: true
    });
    const id = match.params.id;
    const onHide = () => {
        history.goBack()
    }

    useEffect(() => {
        metadata.filters.objectId = `${id}`
        dispatch(fetchLogsForFoldersDetail(metadata));
        dispatch(fetchFolderForDetails(id));
    }, [id, dispatch]);

    return (
        <>
            <Loader isLoading={isLoading}/>
            <Modal.Dialog
                className={'max-w-100'}
            >
                <Modal.Body>
                    {
                        !isLoading && folder &&
                        <ComponentToPrint folder={folder} data={data} ref={componentRef}/>
                    }


                </Modal.Body>
                <Modal.Footer>
                    {/*<AEButton*/}
                    {/*  fontWeight={'bold'}*/}
                    {/*  onClick={handlePrint}*/}
                    {/*>*/}
                    {/*  {formatMessage({id: 'PRINT'})}*/}
                    {/*</AEButton>*/}

                    <AEButton
                        variant={'light'}
                        onClick={onHide}
                    >
                        {formatMessage({id: 'BACK'})}
                    </AEButton>
                </Modal.Footer>
            </Modal.Dialog>
            {/*</Modal>*/}
        </>

    );
}

