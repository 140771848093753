import axios from "axios";
import {axiosFormDataConfigs, getFormData} from "../../../_ae/helpers/AxiosHelpers";
import {toAbsoluteApiUrl} from "../../../_metronic/_helpers";
import {MODULES} from "../../../_ae/helpers/RoutingHelpers";

export const API_URI = toAbsoluteApiUrl(`/${MODULES.FOLDERS}`);

const normalize = _entity => {
    const {id, reference, phone, notes, clientName, matricule, marque} = _entity;

    let assignTo = undefined;
    if (_entity.assignTo) {
        assignTo = {id: _entity.assignTo.id}
    }

    let city = undefined;
    if (_entity.city) {
        city = {id: _entity.city.id}
    }

    return {id, reference, phone, notes, assignTo, city, clientName, matricule, marque}
}

const normalizeLog = _entity => {
    const {id, cabinetNote, status} = _entity;

    let assignTo = undefined;
    if (_entity.assignTo) {
        assignTo = {id: _entity.assignTo.id}
    }

    let city = undefined;
    if (_entity.city) {
        city = {id: _entity.city.id}
    }
    let folder = undefined;
    if (_entity.folderId) {
        folder = {id: _entity.folderId}
    }

    return {id, cabinetNote, folder, assignTo, city, status}
}

export const all = (metadata) => axios.get(API_URI, {params: {meta: metadata}});
export const find = (id, metadata) => axios.get(`${API_URI}/${id}`, {params: {meta: metadata}});
export const destroy = id => axios.delete(`${API_URI}/${id}`);
export const create = (entity, files) => axios.post(API_URI, getFormData(normalize(entity), files), axiosFormDataConfigs);
export const update = entity => axios.post(`${API_URI}/${entity.id}?_method=PUT`, getFormData(normalize(entity)), axiosFormDataConfigs);


export const createLog = (entity, files) => axios.post(toAbsoluteApiUrl('/folder_logs'), getFormData(normalizeLog(entity), files), axiosFormDataConfigs);
