import React from "react";
import {useIntl} from "react-intl";
import {AEAutocompleteField, AEField} from "../../../../../_metronic/_partials/controls/forms/AEField";
import {useRoutesForRolesEditState} from "../../../../../redux/store/routes";
import {FieldArray} from "formik";
import {AEButton} from "../../../../../_ae/components/buttons";
import {AESVG, SVG_ICON} from "../../../../../_ae/components/svg";


//todo server validation errors
export const Form = ({
                       values,
                       errors,
                       touched,
                       handleChange,
                       handleBlur,
                       handleSubmit,
                       isSubmitting,
                       btnRef,
}) => {

  const {formatMessage} = useIntl()
  const {data: routes, isLoading: routeLoading} = useRoutesForRolesEditState()

  return (
    <div className="form form-label-right">
      <div className="form-group row">
        <div className={"col-lg-12"}>
          <AEField
            name="name"
            label={'NAME'}
          />
        </div>
      </div>
      <div className="form-group">

        <FieldArray
          name="roleRoutes"
          render={(helpers) => (
            <div>
              <div className={'d-flex align-items-center form-group'}>
                <span className="font-size-h3 font-weight-bold">
                  {formatMessage({id: 'ROUTES'})}
                </span>
                <AEButton
                  variant={'clean'}
                  icon
                  onClick={()=>{
                    helpers.push( {
                      route: undefined
                    })
                  }}
                >
                  <AESVG
                    variant={'primary'}
                    path={SVG_ICON.PLUS}
                    size={'lg'}
                  />
                </AEButton>
              </div>
              {
                !routeLoading &&
                values.roleRoutes.map((roleRoute, index)=>(
                  <div key={index} className="d-flex form-group">
                    <div className={'flex-grow-1'}>
                      <AEAutocompleteField
                        name={`roleRoutes.${index}.route`}
                        label={'ROUTE'}
                        options={routes}
                        getOptionLabel={o=> formatMessage({id: o.routeId})}
                      />
                    </div>
                    <div className={''}>
                      <AEButton
                        variant={'clean'}
                        icon
                        onClick={()=>{
                          helpers.remove(index)
                        }}
                      >
                        <AESVG
                          variant={'danger'}
                          path={SVG_ICON.DELETE}
                          size={'lg'}
                        />
                      </AEButton>
                    </div>
                  </div>
                ))
              }
            </div>
          )}
        />
      </div>



      <button
        style={{ display: "none" }}
        ref={btnRef}
        onClick={handleSubmit}
      />
    </div>
  );
}

