import React, {useEffect} from "react";
import {Pagination} from "../../../../../_metronic/_partials/controls";
import {useDispatch, useSelector} from "react-redux";
import {EntityCard} from "../../components/EntityCard";
import {Filter} from "./Filter";
import {useMetadata} from "../../../../../_ae/AEPagination";
import {fetchFolders, useFoldersListState, useAllFoldersListState, fetchAllFolders} from "../../../../../redux/store/folders";
import {Loader} from "../../../../../_ae/components/loader";
import {getConfig} from "../../../../../_metronic/i18n";
import {Accordion, Card} from "react-bootstrap";
import {getStatusKeys} from "../../components/Status";
import {useIntl} from "react-intl";

export const List = () => {
    const dispatch = useDispatch();
    const {data, isLoading, metadata} = useFoldersListState();
    const {data: allFolders} = useAllFoldersListState();
    const {role} = getConfig();
    const authUser = useSelector(state => state.auth.user);
    const {formatMessage} = useIntl()


    const metadataState = useMetadata(metadata)
    const {page, perPage, sortAsc, filters, sortField, select} = metadataState;

    const getEntities = () => {
        const mt = metadataState.toAEMetadata();
        // if (role === ROLES.A) {
        //     mt.filters.assignTo = {id: authUser.id}
        // }
        dispatch(fetchFolders(mt));
    }

    /* Hooks */
    useEffect(() => {
        getEntities()
    }, [
        dispatch, page, perPage,
        filters.search, filters.assignTo, /*filters.status,*/,
        select, sortField, sortAsc
    ]);

   let ongoing,canceled, validated,closed, totals = [];
   if(allFolders && allFolders.length > 0 )
   {
        ongoing = allFolders.filter(y => y._status === "ONGOING");
        canceled = allFolders.filter(y => y._status === "CANCELLED")
        validated = allFolders.filter(y => y._status === "VALIDATED")
        closed = allFolders.filter(y => y._status === "CLOSED");

        totals['ONGOING'] = ongoing.length;
        totals['CANCELLED'] = canceled.length;
        totals['VALIDATED'] = validated.length;
        totals['CLOSED'] = closed.length;
    }

    /* Hooks */
    useEffect(() => {
        dispatch(fetchAllFolders());
    }, [dispatch]);
    //todo filters: status, city

    return (
        <>
            <Loader isLoading={isLoading}/>
            <Filter {...metadataState}/>

            <Accordion  defaultActiveKey="0">


                {
                    getStatusKeys().map((status, index) => (

                        <Card>
                            <Accordion.Toggle
                                as={Card.Header} eventKey={index}>
                                <h3 className={"text-uppercase my-2"}>
                                    <i className="fas fa-angle-double-right mr-2"></i>
                                    Dossiers {formatMessage({id: status})} ({totals[status]})
                                </h3>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey={index}>
                                <Card.Body>
                                    <div className={'row'}>
                                        {
                                            data.filter(item => item._status === status).map(r => (
                                                <div key={r.id} className={'col-md-6'}>
                                                    <EntityCard
                                                        key={r.id}
                                                        entity={r}
                                                        size={'md'}
                                                        className={'card-stretch'}
                                                        editAction
                                                        deleteAction
                                                        closeAction
                                                        detailsAction
                                                        addUpdate
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                    ))
                }
                {/*<Card>*/}
                {/*    <Accordion.Toggle*/}
                {/*        as={Card.Header} eventKey="0">*/}
                {/*        <h3 className={"text-uppercase"}> Dossiers validés</h3>*/}
                {/*    </Accordion.Toggle>*/}

                {/*    <Accordion.Collapse eventKey="0">*/}
                {/*        <Card.Body>This is first tab body</Card.Body>*/}
                {/*    </Accordion.Collapse>*/}
                {/*</Card>*/}

                {/*<Card>*/}
                {/*    <Accordion.Toggle as={Card.Header} eventKey="1">*/}
                {/*        <h3 className={"text-uppercase"}> Dossiers En cours de traitement</h3>*/}

                {/*    </Accordion.Toggle>*/}

                {/*    <Accordion.Collapse eventKey="1">*/}
                {/*        <Card.Body>This is second tab body</Card.Body>*/}
                {/*    </Accordion.Collapse>*/}
                {/*</Card>*/}
            </Accordion>


            <Pagination {...metadataState} counts={[16, 32, 64, 128]}/>
        </>
    );
}
